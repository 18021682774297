

// Funzione per annullare il comportamento del tasto enter
$('#newsletter-email').on('keypress', function (e) {
    if(e.which == 13) {
        e.preventDefault();
    }
});

function gestisciIscrizione(e) { // riceve l'evento e come parametro
    var email = $('#newsletter-email').val();
    var test = shelf.validaEmail(email);
    if (shelf.validaEmail(email)) {

        var f = $('#newsletterForm');
        var data = f.serialize();

        //Esegui una richiesta AJAX per inviare l'indirizzo email al server
        shelf.post('/storeNewsletterEmail', 'POST', null,

            function (response) {

                if (response.response === 'permitted') {
                    shelf.createToast(shelf.toastrType.SUCCESS, response.message,
                        'toast-bottom-right');
                    $("#send-email").removeClass("custom-error");
                    $("#send-email").addClass("custom-success");
                    shelf.animateButton(e); // chiama la funzione animateButton con l'evento e
                    $('#newsletter-email').val('');
                } else {
                    shelf.createToast(shelf.toastrType.ERROR, response.message,
                        'toast-bottom-right');
                }

            },

            function (xhr, status, error) {

                shelf.createToast(shelf.toastrType.ERROR, error.message,
                    'toast-bottom-right');
                $("#send-email").removeClass("custom-success");
                $("#send-email").addClass("custom-error");
                shelf.animateButton(e);
            },

            data

        );

    } else {
        shelf.createToast(shelf.toastrType.ERROR, 'Inserisci un indirizzo email valido.',
            'toast-bottom-right');
        $("#send-email").removeClass("custom-success");
        $("#send-email").addClass("custom-error");
        shelf.animateButton(e); // chiama la funzione animateButton con l'evento e
    }

}

$("#send-email").on("click", function (e) {
    e.preventDefault(); // Evita il comportamento predefinito del form
    gestisciIscrizione(e);
    shelf.animateButton(e);
});





// Funzione per annullare il comportamento del tasto enter
$('#newsletter_email_footer').on('keypress', function (e) {
    if(e.which == 13) {
        e.preventDefault();
    }
});

function gestisciIscrizioneFooter(e) { // riceve l'evento e come parametro
    var email = $('#newsletter_email_footer').val();
    var test = shelf.validaEmail(email);
    if (shelf.validaEmail(email)) {

        var f = $('#newsletterFormFooter');
        var data = f.serialize();

        //Esegui una richiesta AJAX per inviare l'indirizzo email al server
        shelf.post('/storeNewsletterEmail', 'POST', null,

            function (response) {

                if (response.response === 'permitted') {
                    shelf.createToast(shelf.toastrType.SUCCESS, response.message,
                        'toast-bottom-right');
                    $("#send_email_footer").removeClass("custom-error");
                    $("#send_email_footer").addClass("custom-success");
                    shelf.animateButton(e); // chiama la funzione animateButton con l'evento e
                    $('#newsletter_email_footer').val('');
                } else {
                    shelf.createToast(shelf.toastrType.ERROR, response.message,
                        'toast-bottom-right');
                }

            },

            function (xhr, status, error) {

                shelf.createToast(shelf.toastrType.ERROR, error.message,
                    'toast-bottom-right');
                $("#send_email_footer").removeClass("custom-success");
                $("#send_email_footer").addClass("custom-error");
                shelf.animateButton(e);
            },

            data

        );

    } else {
        shelf.createToast(shelf.toastrType.ERROR, 'Inserisci un indirizzo email valido.',
            'toast-bottom-right');
        $("#send_email_footer").removeClass("custom-success");
        $("#send_email_footer").addClass("custom-error");
        shelf.animateButton(e); // chiama la funzione animateButton con l'evento e
    }

}

$("#send_email_footer").on("click", function (e) {
    e.preventDefault(); // Evita il comportamento predefinito del form
    gestisciIscrizioneFooter(e);
    shelf.animateButton(e);
});













